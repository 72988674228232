import React from "react";
export default function About() {

    return (
        <>
            <div className="container">
                <div className="body row">
                    <div className="staff">
                        <div className="col-md-2">
                            <img src="img/headshot.jpg" alt="head shot" />
                        </div>
                        <div className="bio col-md-10">
                            <h2>Laura Mills</h2>
                            <h4>Consultant</h4>
                            <p>I worked at Chambers & Partners for nine years — the last six of which as the longest-serving editor of the Chambers USA Guide and the three years prior as a researcher and deputy editor. During my tenure overseeing the USA Guide, the level of participation increased by 30%, reaching just under 11,000 submissions, thanks in part to my drive to improve transparency in the research process and my desire to help firms improve their submissions.</p>
                            <p>I now take my extensive knowledge of the guides and the legal market, and the experience I gained sharing best practice insights, to work more closely with firms to improve their performance in and understanding of directories like Chambers and Legal 500. Since establishing LC Mills Consulting in 2017, I have been fortunate to work with firms of all sizes, hailing from dozens of jurisdictions, practicing all manner of law.</p>
                            <p>I am a dual US/UK citizen and, after 17 years in London, I moved back to my hometown of Buffalo, NY in 2023 with my husband, son, and daughter. I have a BA in History and Middle Eastern Studies from the University of Pittsburgh and an MA in Middle Eastern Studies from the School of Oriental and African Studies – University of London. In my free time, you can find me supporting the Buffalo Bills and The Arsenal.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
