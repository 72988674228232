import React from "react";
export default function Blog() {

    return (
        <>
            <div className="container">
                <div className="body">

                </div>
            </div>
        </>
    );
}
