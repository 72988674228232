import React, { useState } from "react";

function ContactForm({ onSubmit }) {
    return (<>
        <p>Please feel free to contact us</p>
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <label htmlFor="nameInput">Name</label>
                <input type="text" className="form-control" name="name" id="nameInput" placeholder="Your name" />
            </div>
            <div className="form-group">
                <label htmlFor="organizationInput">Organization</label>
                <input type="text" className="form-control" name="organization" id="organizationInput" placeholder="Organization" />
            </div>
            <div className="form-group">
                <label htmlFor="emailInput">Email address</label>
                <input type="email" className="form-control" name="email" id="emailInput" placeholder="Enter email" />
            </div>
            <div className="form-group">
                <label htmlFor="phoneInput">Phone Number</label>
                <input type="tel" className="form-control" name="phone" id="phoneInput" placeholder="Enter phone" />
            </div>
            <div className="form-group">
                <label htmlFor="messageInput">Message</label>
                <textarea className="form-control" id="messageInput" name="message" placeholder="Your message" rows="10"></textarea>
            </div>
            <button className="btn button" type="submit">Send</button>
        </form>
    </>)
}

function ThankYou() {
    return <p>Thank you for your inquiry</p>
}

export default function Contact() {
    const [isSubmitted, setSubmitted] = useState(false);

    function onSubmit(event) {
        event.preventDefault();
        const data = {
            name: event.target.name.value,
            organization: event.target.organization.value,
            email: event.target.email.value,
            phone: event.target.phone.value,
            message: event.target.message.value
        };
        console.log(data)
        fetch("https://xvkt3xed75.execute-api.eu-west-1.amazonaws.com/prod/contact-us", {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((res) => setSubmitted(true));
    }

    return (
        <>
            <div className="container">
                <div className="body">
                    <div className="row">
                        <div className="col-md-offset-3 col-md-6">
                            <h2>Contact Us</h2>
                            <div id="contact">
                                {isSubmitted ? (<ThankYou />) : (<ContactForm onSubmit={onSubmit} />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
