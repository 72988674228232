import React from "react";
export default function Home() {
    return (
        <>
            <div className="container">
                <div className="body">
                    <div id="best-practices" className="service">
                        <h2>Best Practice Seminars</h2>
                        <p>Best practice seminars can be tailored to address large groups or small, partners or support staff – I will run your firm through what the directories do (and don't) want to see, how to write a clear and compelling work highlight, give you tips on references, and help to set informed expectations on building your firm's rankings. There will be plenty of time to have all of your questions answered – even the one asking why on earth you're putting directories submissions together.</p>
                    </div>

                    <div id="writing" className="service">
                        <h2>Submission writing</h2>
                        <p>Whether you'd like targeted help for one practice that you're keen to put in the best possible light or you'd like to alleviate some of the burden on your marketing/BD team, I can help you to write the submission that best shows off what you're trying to convey about your group and highlight the people you think are most deserving of recognition</p>
                    </div>

                    <div id="submissions" className="service">
                        <h2>Submission/practice deep-dive</h2>
                        <p>There's always one practice or individual that you especially feel needs to be recognized more for their work – I can sit down with you and analyze your previous efforts on their behalf as well as the current rankings, to come up with a targeted plan to improve your coverage.</p>
                    </div>

                    <div id="unpublished" className="service">
                        <h2>Making the most from your Chambers Unpublished report</h2>
                        <p>You've gotten your Unpublished report and read the inside scoop, but there is often a lot more to your Chambers Unpublished report than meets the eye. The report can provide extremely valuable insight to the health of your current rankings and the potential for your future rankings. I will sit down with you to go through your most recent Unpublished report to find out what you can learn beyond the analysis that Chambers has provided.</p>
                    </div>

                    <div id="copyediting" className="service">
                        <h2>Copyediting/Writing</h2>
                        <p>I am an experienced editor with almost a decade of business writing and editing under my belt. If you need a copyeditor or writer who is knowledgeable about the legal industry and who quickly comes to grips with an organization's house style – look no further. I am happy to handle large or small projects on an ad hoc basis, in American or British English.</p>
                    </div>
                </div>
            </div>

            <div className="container"></div>
        </>
    );
}
